.deploy-button-container {
  left: 4rem;
  right: 50%;
  bottom: 0;
}

.deploy-button {
  font-weight: 700;
  border: none;
  background-color: #006ff5;
  border-radius: 100px;
}

.editor-container {
  height: 100%;
}

.editor {
  margin: 0;
  height: 100vh;
}

textarea {
  background: transparent;
}

.editor-code,
.editor-compiled {
  display: inline-block;
  width: 50vw;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.editor-code {
  background-color: #FCFCE2;
}

.editor-code-textarea {
  border: none;
  resize: none;
  outline: none;
  font-family: "IBM Plex Mono", monospace;
  width: 100%;
  min-height: calc(100vh - 250px);
}

.est {
  color: #CCE2FD;
}

.message {
  border-bottom: 2px solid #FCFCE2;
}

.preview h1 {
  margin: 0;
  padding-top: 1rem;
}

.preview {
  outline: 24px solid #FFF;

  border: none;
}

.editor-compiled::before {
  content: "((( Preview )))";
  display: inline-block;
  transform: rotate(35deg);
  padding: 8px 118px;
  position: absolute;
  right: -91px;
  top: 40px;
  font-size: 16px;
}

.editor-compiled::before {
  font-style: italic;
}

.editor-timestamp {
  color: #006FF5;
}

.editor-timestamp,
.timestamp,
.editor-compiled::before {
  font-weight: 700;
}

@keyframes changeBg {

  0% {
    background: #fceae2;
  }
  33% {
    background: #CCE2FD;
  }
  100% {
    background: #FCFCE2;
  }

}

.editor-compiled::before {
  animation: changeBg 5s linear alternate infinite;
}

@keyframes changeShadow {

  0% {
    box-shadow: 0 0 0 30px #fceae2;
  }
  33% {
    box-shadow: 0 0 0 30px #CCE2FD;
  }
  100% {
    box-shadow: 0 0 0 30px #FCFCE2;
  }

}

.preview {
  animation: changeShadow 5s linear alternate infinite;
}
