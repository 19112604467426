@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans:400,400i,700,700i&display=swap');

body {
  color: #333;
  line-height: 1.75;
  padding: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  font-family: "IBM Plex Mono", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: #006FF5;
}

a:hover {
  text-decoration: underline;
}

p {
  font-size: 20px;
  margin: 6px 0;
}
