@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans:400,400i,700,700i&display=swap);
body {
  color: #333;
  line-height: 1.75;
  padding: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  font-family: "IBM Plex Mono", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: #006FF5;
}

a:hover {
  text-decoration: underline;
}

p {
  font-size: 20px;
  margin: 6px 0;
}


.deploy-button-container {
  left: 4rem;
  right: 50%;
  bottom: 0;
}

.deploy-button {
  font-weight: 700;
  border: none;
  background-color: #006ff5;
  border-radius: 100px;
}

.editor-container {
  height: 100%;
}

.editor {
  margin: 0;
  height: 100vh;
}

textarea {
  background: transparent;
}

.editor-code,
.editor-compiled {
  display: inline-block;
  width: 50vw;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.editor-code {
  background-color: #FCFCE2;
}

.editor-code-textarea {
  border: none;
  resize: none;
  outline: none;
  font-family: "IBM Plex Mono", monospace;
  width: 100%;
  min-height: calc(100vh - 250px);
}

.est {
  color: #CCE2FD;
}

.message {
  border-bottom: 2px solid #FCFCE2;
}

.preview h1 {
  margin: 0;
  padding-top: 1rem;
}

.preview {
  outline: 24px solid #FFF;

  border: none;
}

.editor-compiled::before {
  content: "((( Preview )))";
  display: inline-block;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  padding: 8px 118px;
  position: absolute;
  right: -91px;
  top: 40px;
  font-size: 16px;
}

.editor-compiled::before {
  font-style: italic;
}

.editor-timestamp {
  color: #006FF5;
}

.editor-timestamp,
.timestamp,
.editor-compiled::before {
  font-weight: 700;
}

@-webkit-keyframes changeBg {

  0% {
    background: #fceae2;
  }
  33% {
    background: #CCE2FD;
  }
  100% {
    background: #FCFCE2;
  }

}

@keyframes changeBg {

  0% {
    background: #fceae2;
  }
  33% {
    background: #CCE2FD;
  }
  100% {
    background: #FCFCE2;
  }

}

.editor-compiled::before {
  -webkit-animation: changeBg 5s linear alternate infinite;
          animation: changeBg 5s linear alternate infinite;
}

@-webkit-keyframes changeShadow {

  0% {
    box-shadow: 0 0 0 30px #fceae2;
  }
  33% {
    box-shadow: 0 0 0 30px #CCE2FD;
  }
  100% {
    box-shadow: 0 0 0 30px #FCFCE2;
  }

}

@keyframes changeShadow {

  0% {
    box-shadow: 0 0 0 30px #fceae2;
  }
  33% {
    box-shadow: 0 0 0 30px #CCE2FD;
  }
  100% {
    box-shadow: 0 0 0 30px #FCFCE2;
  }

}

.preview {
  -webkit-animation: changeShadow 5s linear alternate infinite;
          animation: changeShadow 5s linear alternate infinite;
}

